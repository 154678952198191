<template>
  <div>
    <div class="d-flex flex-row">
      <p class="text-2xl mb-2">Airflow</p>
      <v-spacer></v-spacer>
      <dev-menu></dev-menu>
    </div>
    <v-card class="mx-auto">
      <v-card-title class="text-h6 font-weight-regular justify-space-between">
        <span>{{ currentTitle }}</span>
        <v-avatar color="primary" size="24" v-text="step"></v-avatar>
      </v-card-title>
      <v-card-text v-if="step == 1">
        <v-row class="px-5">
          <p>
            Une fois un workflow sélectionné, vous pourrez cliquer sur le bouton
            "Status" pour en savoir plus suir lui.
            <br />
            Les workflow en bleu sont ceux permettant d'être lancé via cette
            interface (bouton "Next")
          </p>
        </v-row>
      </v-card-text>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="filteredItems"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              single-select
              item-key="dag_id"
              show-select
              class="elevation-1"
              disable-pagination
              :items-per-page="itemsPerPage"
            >
              <template v-slot:top>
                <div class="d-flex flex-row mb-6">
                  <v-text-field
                    v-model="searchStr"
                    append-icon="mdi-magnify"
                    label="Search"
                    @input="search"
                    single-line
                    hide-details
                    class="ma-2"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!selected.length"
                    v-on:click="showDagInfos"
                    color="primary"
                    elevation="4"
                    class="ma-2"
                  >
                    <v-icon>
                      {{ icons.mdiStateMachine }}
                    </v-icon>
                    <div class="mx-2">Status</div>
                  </v-btn>
                </div>
              </template>
              <template v-slot:item.dag_id="{ item }">
                <div
                  :class="
                    dagsHandled.includes(item.dag_id)
                      ? `dag-handled`
                      : `dag-not-handled`
                  "
                  class="time-cell"
                  style="text-align: left"
                >
                  {{ item.dag_id }}
                </div>
              </template>
              <template v-slot:item.next_dagrun="{ item }">
                <div class="time-cell" style="text-align: right">
                  {{ truncate(item.next_dagrun, 16, "") }}
                </div>
              </template>
              <template v-slot:item.last_run_state="{ item }">
                <v-chip
                  :class="getColor(item.last_run_state)"
                  dark
                  class="mr-3"
                >
                  {{ item.last_run_state }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <partner-get-and-store-daily
            v-if="
              [
                'partner_get_and_store_daily_dag',
                'test_with_params_dag',
              ].includes(selectedDagId)
            "
            :partnersList="partnersList"
            @onUpdate="updateDagParams"
          ></partner-get-and-store-daily>
          <ga-get-and-store
            v-if="
              ['ga_get_and_store_manual_dag', 'test_with_params_dag'].includes(
                selectedDagId
              )
            "
            :siteList="siteList"
            @onUpdate="updateDagParams"
          ></ga-get-and-store>
        </v-window-item>

        <v-window-item :value="3">
          <div class="pa-4 text-center">
            <h3 class="text-h6 font-weight-light mb-2">
              Lancer le workflow <b>{{ selectedDagId }}</b>
            </h3>
            <div
              v-if="dagParamsTexts.length > 0"
              class="text-h5 font-weight-light mb-2"
            >
              avec les paramètres suivant :
            </div>
            <v-row v-if="dagParamsTexts.length > 0">
              <v-col cols="2"> </v-col>
              <v-col cols="8" class="text-left">
                <div v-if="!paramsDevVersion">
                  <ul>
                    <li v-for="(text, i) in dagParamsTexts">{{ text }}</li>
                  </ul>
                </div>
                <div v-else>
                  <ul v-show="Object.keys(dagParams).length">
                    <li v-for="(value, propertyName) in dagParams">
                      {{ propertyName }} : {{ value }}
                    </li>
                  </ul>
                  <div
                    v-if="dagParamsCommand != ''"
                    class="text-caption text-grey pt-2"
                  >
                    {{ dagParamsCommand }}
                  </div>
                  <div v-else class="text-caption text-grey">
                    Aucun paramètre nécessaire à passer car ce sont ceux par
                    défaut.
                  </div>
                </div>

                <v-switch
                  v-model="paramsDevVersion"
                  label="Version pour dévelopeur"
                  inset
                ></v-switch>
              </v-col>
              <v-col cols="2"> </v-col>
            </v-row>
            <v-row>
              <v-col cols="3"> </v-col>
              <v-col cols="6" class="text-left"> </v-col>
              <v-col cols="3"> </v-col>
            </v-row>
            <v-btn
              color="primary"
              class="mt-4"
              @click="runDag"
              :disabled="dagParamsErrors.length !== 0"
            >
              Lancer le workflow
            </v-btn>
          </div>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn v-if="btnBackShow" variant="text" @click="step--"> Back </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="btnNextShow"
          color="primary"
          variant="flat"
          @click="gotoNextStep"
        >
          Next
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import store from "@/store";
import { truncate, formatTime } from "@/utils/formatting";
import {
  mdiDelete,
  mdiPlus,
  mdiMagnify,
  mdiStateMachine,
  mdiTextBoxMultipleOutline,
} from "@mdi/js";
import PartnerGetAndStoreDaily from "@/views/pages/admin/includes/airflow/PartnerGetAndStoreDaily";
import GaGetAndStore from "@/views/pages/admin/includes/airflow/GaGetAndStore";
import DevMenu from "@/views/menus/DevMenu.vue";

export default {
  name: "Airflow",
  components: { DevMenu, PartnerGetAndStoreDaily, GaGetAndStore },
  data() {
    return {
      partnersList: [],
      siteList: [],
      step: 1,
      paramsDevVersion: false,
      searchStr: "",
      selected: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: -1,
      sortBy: "dag_id",
      sortDesc: false,
      dialog: false,
      dialogTitle: "",
      dialogText: "",
      dialogCode: "",
      isOpenCreateCronDialog: false,
      // dialogCreate: false,
      identifier: false,
      getDagsTimeout: null,
      items: [],
      filteredItems: [],
      dagsHandled: [
        "test_with_params_dag",
        "partner_get_and_store_daily_dag",
        "ga_get_and_store_manual_dag",
      ],
      dagsDescription: {
        test_with_params_dag:
          "Workflow de test permettant de lancer un workflow avec des paramètres sans conséquences",
        partner_get_and_store_daily_dag:
          "Workflow Programmatique chargé de récupérer les données de partenaire(s).",
        partner_gmail_credentials_check_dag:
          "Workflow Programmatique pour essayer de refresh les credentials de gmail quand c'est possible",
        ga_get_and_store_manual_dag:
          "Workflow chargé de récupérer les sessions et pages vues des sites via l'API Google Analytics.",
      },
      dagParams: {},
      dagParamsErrors: [],
      dagParamsTexts: [],
      dagParamsCommand: "",
      headers: [
        {
          text: "Workflow Identifier",
          align: "start",
          sortable: true,
          value: "dag_id",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: "Description de la périodicité",
          align: "start",
          sortable: true,
          value: "timetable_description",
        },
        {
          text: "Has errors",
          align: "start",
          sortable: true,
          value: "has_import_errors",
        },
        {
          text: "is_active",
          align: "start",
          sortable: true,
          value: "is_active",
        },
        {
          text: "Run state",
          align: "start",
          sortable: true,
          value: "last_run_state",
        },
      ],
      icons: {
        mdiStateMachine,
        mdiPlus,
        mdiDelete,
        mdiTextBoxMultipleOutline,
        mdiMagnify,
      },
    };
  },
  async mounted() {
    this.partnersList = await this.$store.dispatch("premium/getPartnersList");
    this.siteList = await this.$store.dispatch("common/getSiteList");
    this.getDagList();
  },
  methods: {
    async runDag() {
      console.log("runDag", this.dagParams);
      const payload = {
        dag_id: this.selected[0].dag_id,
        dag_params: this.dagParams,
      };
      const response = await axios.post("/airflow/dag/run", payload);
      console.log("runDag response", response);
      if (response.status === 200) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Workflow ${this.selectedDagId} lancé avec succès !`,
          color: "success",
          timeout: 5000,
        });
        this.step = 1;
      } else {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Erreur(s): ${JSON.stringify(error.response.data, null, 2)}`,
          color: "warning",
          timeout: 5000,
        });
      }
    },
    updateDagParams(dagParamsInfos) {
      console.log("updateDagParams dagParamsInfos", dagParamsInfos);
      this.dagParams = dagParamsInfos.params;
      this.dagParamsErrors = dagParamsInfos.errors;
      this.dagParamsTexts = dagParamsInfos.texts;
      this.dagParamsCommand = dagParamsInfos.command;
    },
    askGetDagList() {
      if (this.getDagsTimeout) {
        clearTimeout(this.getDagsTimeout);
        this.getDagsTimeout = null;
      }
      setTimeout(() => {
        this.getDagList();
      }, 500);
    },
    async getDagList() {
      const items = this.dagList;
      console.log("response", items);
      // let items = [
      //   {
      //     dag_id: "ga_get_and_store_manual_dag",
      //     is_active: true,
      //   },
      //   {
      //     dag_id: "partner_get_and_store_daily_dag",
      //     is_active: true,
      //   },
      // ];
      for (const item of items) {
        item.description =
          item.dag_id in this.dagsDescription
            ? this.dagsDescription[item.dag_id]
            : "";
      }
      this.items = items;
      this.setFilteredItems();
      console.log("this.items", this.items);
    },
    async showDagInfos() {
      console.log("showDagInfos");
      await store.dispatch("app/setDialog", {
        show: true,
        title: "Dag Infos",
        text: "Dag Infos",
        code: JSON.stringify(this.selected[0], null, 2),
      });
    },
    async gotoNextStep() {
      console.log("gotoNextStep");
      if (
        this.step === 1 &&
        (this.selected.length == 0 ||
          !this.dagsHandled.includes(this.selected[0].dag_id))
      ) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Erreur: Il faut sélectionner un workflow pris en charge (en bleu) pour continuer.`,
          color: "warning",
          timeout: 3000,
        });
        return;
      }
      if (
        this.step === 1 &&
        this.selected.length == 1 &&
        this.selected[0].last_run_state === "running"
      ) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: "Erreur: Ce workflow est en cours de traitement. Veuillez attendre qu'il se termine avant de le relancer.",
          color: "warning",
          timeout: 3000,
        });
        return;
      }
      if (this.step === 2 && this.dagParamsErrors.length > 0) {
        await store.dispatch("app/setSnackBar", {
          show: true,
          text: `Erreur(s): ${this.dagParamsErrors.join(", ")}`,
          color: "warning",
          timeout: 3000,
        });
        return;
      }
      this.step++;
    },
    search() {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }
      setTimeout(() => {
        this.setFilteredItems();
      }, 500);
    },
    setFilteredItems() {
      const filteredItems = [];
      const searchStrLC = this.searchStr.toLowerCase();
      for (let i = 0; i < this.items.length; i++) {
        if (
          this.searchStr == "" ||
          this.items[i]["dag_id"].toLowerCase().includes(searchStrLC)
        ) {
          filteredItems.push(this.items[i]);
        }
      }
      this.filteredItems = filteredItems;
    },
    getColor(last_run_state) {
      let colors = {
        running: "blue",
        success: "green",
        failed: "red",
        undefined: "black",
      };
      return colors[last_run_state];
    },
  },
  computed: {
    dagList() {
      return this.$store.getters["common/getAirflowDagList"];
    },
    selectedDagId() {
      return this.selected.length > 0
        ? this.selected[0].dag_id
        : "partner_get_and_store_daily_dag"; // null;
    },
    btnNextShow() {
      return this.step < 3;
    },
    btnBackShow() {
      return this.step > 1;
    },
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Sélectionner un workflow";
        case 2:
          return `Configurer le workflow ${this.selected[0].dag_id}`;
        case 3:
          return "Récapitulatif et lancement";
        default:
          return "Default title";
      }
    },
    truncate() {
      return truncate;
    },
    formatTime() {
      return formatTime;
    },
  },
  watch: {
    async dagList() {
      await this.getDagList();
    },
  },
};
</script>
<style scoped>
div.dag-not-handled {
  color: gray;
}
div.dag-handled {
  color: blue;
}

.v-chip.v-chip--no-color.blue {
  background-color: blue;
}
.v-chip.v-chip--no-color.green {
  background-color: green;
}
.v-chip.v-chip--no-color.orange {
  background-color: orange;
}
.v-chip.v-chip--no-color.red {
  background-color: red;
}
.v-chip.v-chip--no-color.black {
  background-color: black;
}
</style>
<!--
  
{
  "dag_id": "test_with_params_dag",
  "default_view": "grid",
  "description": null,
  "file_token": "Ii9wcm9qZWN0cy9yZXBvcnQtYXBpL2FpcmZsb3cvZGFncy90ZXN0X3dpdGhfcGFyYW1zX2RhZy5weSI.GJS8KwSz3-_QSgEzOcpCOS_Jwec",
  "fileloc": "/projects/report-api/airflow/dags/test_with_params_dag.py",
  "has_import_errors": false,
  "has_task_concurrency_limits": false,
  "is_active": true,
  "is_paused": false,
  "is_subdag": false,
  "last_expired": null,
  "last_parsed_time": "2023-03-31T14:39:32.306212+00:00",
  "last_pickled": null,
  "max_active_runs": 16,
  "max_active_tasks": 16,
  "next_dagrun": null,
  "next_dagrun_create_after": null,
  "next_dagrun_data_interval_end": null,
  "next_dagrun_data_interval_start": null,
  "owners": [
  "proton"
  ],
  "pickle_id": null,
  "root_dag_id": null,
  "schedule_interval": null,
  "scheduler_lock": null,
  "tags": [],
  "timetable_description": "Never, external triggers only",
  "run": {
    "conf": {
      "force_api": false
    },
    "dag_id": "test_with_params_dag",
    "dag_run_id": "test_with_params_dag_1680270594.936593",
    "data_interval_end": "2023-03-31T13:49:55.209884+00:00",
    "data_interval_start": "2023-03-31T13:49:55.209884+00:00",
    "end_date": "2023-03-31T13:54:58.415892+00:00",
    "execution_date": "2023-03-31T13:49:55.209884+00:00",
    "external_trigger": true,
    "last_scheduling_decision": "2023-03-31T13:54:58.414027+00:00",
    "logical_date": "2023-03-31T13:49:55.209884+00:00",
    "note": null,
    "run_type": "manual",
    "start_date": "2023-03-31T13:49:55.999348+00:00",
    "state": "success"
  }
  "last_run_state": "success"
}
-->
